import React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { logout } from '../../../store/reducers';
import { Icon } from '../../atoms';
import { NavigationItem } from '../../molecules';
import style from './NavigationDrawer.module.scss';

const cx = classNames.bind(style);

export function NavigationDrawer(): JSX.Element {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const paths = location.pathname.split('/');

  const containerClasses = cx({ NavigationDrawerContainer: true });
  const itemsClasses = cx({ NavigationDrawerItems: true });
  const spacerClasses = cx({ NavigationDrawerSpacer: true });
  const LogoutContainer = cx({ NavigationDrawerLogoutContainer: true });

  return (
    <div className={containerClasses}>
      <Icon icon={'logo'} size={200} />
      <div className={itemsClasses}>
        <NavigationItem link={'/dashboard'} icon={'grid'} active={paths[1] === 'dashboard'}>
          Dashboard
        </NavigationItem>
        <div className={spacerClasses} />
        <NavigationItem link={'/patients'} icon={'account-plus'} active={paths[1] === 'patients'}>
          Patienten
        </NavigationItem>
        <NavigationItem link={'/calendar'} icon={'calendar'} active={false}>
          Kalender
        </NavigationItem>
        <div
          className={LogoutContainer}
          onClick={() => {
            dispatch(logout());
          }}
        >
          <NavigationItem link={'/login'} icon={'logout'} active={false}>
            Abmelden
          </NavigationItem>
        </div>
      </div>
    </div>
  );
}
