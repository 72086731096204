// import React from 'react';
// import { useLocation } from 'react-router-dom';
// import classNames from 'classnames/bind';
// // import type { Patient as PatientType } from '../../../../types';
// import style from './Appointments.module.scss';

// const cx = classNames.bind(style);

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
export function Appointments({ props }: any): JSX.Element {
  return (
    <div>
      <h1>Appointments</h1>
    </div>
  );
}
