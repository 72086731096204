import React from 'react';
import classNames from 'classnames/bind';
import type { Medication as MedicationTypes } from '../../../types';
import { Icon } from '../../atoms';
import style from './PatientNavigation.module.scss';

const cx = classNames.bind(style);

type PatientNavProps = {
  firstname?: string;
  lastname?: string;
  currentPage: string;
  setCurrentPage: React.Dispatch<React.SetStateAction<string>>;
  currentMedication: MedicationTypes[];
  setCurrentMedication: React.Dispatch<React.SetStateAction<MedicationTypes[]>>;
  availableFollowUpPrescription?: number;
  setAvailableFollowUpPrescription: React.Dispatch<React.SetStateAction<number>>;
};

export function PatientNavigation({
  firstname,
  lastname,
  currentPage,
  setCurrentPage,
  currentMedication,
  setCurrentMedication,
  availableFollowUpPrescription,
  setAvailableFollowUpPrescription,
}: PatientNavProps): JSX.Element {
  const containerClasses = cx({ PatientNavigationContainer: true });
  const RowContainer = cx({ PatientNavigationRowContainer: true });
  const MainContainer = cx({ PatientNavigationMainContainer: true });
  const NavItemsContainer = cx({ PatientNavigationNavItemsContainer: true });
  const NameItemsContainers = cx({ PatientNavigationNameItemsContainers: true });
  const NameHeading = cx({ PatientNavigationNameHeading: true });
  const NavItems = cx({ PatientNavigationItems: true });
  const NavSelectedItem = cx({ PatientNavigationSelectedItem: true });

  const onConfirm = (route: string) => {
    const savedMedication: string | null = sessionStorage.getItem('savedMedication');
    const FollowUpPrescription: string | null = sessionStorage.getItem('AvailableFollowUpPrescription');
    setCurrentPage(route);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setCurrentMedication(JSON.parse(savedMedication));
    setAvailableFollowUpPrescription(Number(FollowUpPrescription));
  };

  const onCancel = () => {
    return null;
  };

  const validate = (route: string) => {
    const savedMedication: string | null = sessionStorage.getItem('savedMedication');
    const FollowUpPrescription: string | null = sessionStorage.getItem('AvailableFollowUpPrescription');

    if (
      savedMedication === JSON.stringify(currentMedication) &&
      JSON.stringify(availableFollowUpPrescription) === FollowUpPrescription
    ) {
      setCurrentPage(route);
    } else {
      // eslint-disable-next-line no-unused-expressions, no-alert
      window.confirm('Sie haben ungespeicherte Änderungen\nWollen Sie trotzdem die Seite verlassen?')
        ? onConfirm(route)
        : onCancel();
    }
  };

  return (
    <div className={containerClasses}>
      <div className={RowContainer}>
        <Icon icon={'account-plus'} size={60} />
        <div className={MainContainer}>
          <div className={NameItemsContainers}>
            <p className={NameHeading}>{`${firstname || 'Patient'} ${lastname || 'Name'}`}</p>
          </div>
          <div className={NavItemsContainer}>
            <p
              className={currentPage === 'Overview' ? NavSelectedItem : NavItems}
              onClick={() => {
                validate('Overview');
              }}
            >
              ÜBERSICHT
            </p>
            <p
              className={currentPage === 'Record' ? NavSelectedItem : NavItems}
              onClick={() => {
                validate('Record');
              }}
            >
              AKTE
            </p>
            <p
              className={currentPage === 'Medication' ? NavSelectedItem : NavItems}
              onClick={() => {
                validate('Medication');
              }}
            >
              MEDIKATION
            </p>
            <p
              className={currentPage === 'Appointments' ? NavSelectedItem : NavItems}
              onClick={() => {
                validate('Appointments');
              }}
            >
              TERMINE
            </p>
            <p
              className={currentPage === 'Tracking' ? NavSelectedItem : NavItems}
              onClick={() => {
                validate('Tracking');
              }}
            >
              TRACKING
            </p>
            <p
              className={currentPage === 'FollowRecipe' ? NavSelectedItem : NavItems}
              onClick={() => {
                validate('FollowRecipe');
              }}
            >
              FOLGEREZEPT
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
