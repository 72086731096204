import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BACKEND_URL;
axios.defaults.headers.common = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

type Response = {
  error: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
};

export class UserService {
  static async login(data: { email: string; password: string }): Promise<Response> {
    const stayLoggedIn = false;

    return new Promise((resolve) => {
      axios
        .post('/user/authenticate', { stayLoggedIn, ...data })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async forgotPassword(data: { email: string }): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post('/user/forgot-password', data)
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async resetPassword(data: { token: string; password: string }): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post('/user/reset-password', data)
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getPatients(): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get('/patient', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getPatient(patientId: number): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`/patient/${patientId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getDoctorsComments(patientId: number): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`/patient/${patientId}/remark`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getAppointments(future = false): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`/appointment?future=${future}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getDocuments(patientId: number): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`/patient/${patientId}/file`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getDoctorTasks(): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get('/doctor/tasks', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getPreparation(): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get('/preparation', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async setMedication(data: any): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post('/medication', data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async availableFollowupPrescription(patientId: number, data: any): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .put(`/patient/${patientId}/available-followup-prescription`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async followupPrescriptionRequest(requestId: number, data: any): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .put(`/followup-prescription-request/${requestId}/status`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }
}
