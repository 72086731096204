import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import classNames from 'classnames/bind';
import { UserService } from '../../../services';
import type { Patient } from '../../../types';
import { Button, Loader } from '../../atoms';
import { Input } from '../../molecules';
import { NavigationDrawer } from '../../organisms';
import { PatientsHeader } from './PatientsHeader';
import style from './Patients.module.scss';

const cx = classNames.bind(style);

// Example to Save routes for only authenticated users
// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx

export function Patients(): JSX.Element {
  const [patients, setPatients] = useState<Patient[]>([]);
  const [initialPatients, setInitialPatients] = useState<Patient[]>([]);
  const [numberOfActivePatients, setNumberOfActivePatients] = useState(0);
  const [loading, setLoading] = useState(true);
  const [letter, setLetter] = useState('');
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';
  const today = new Date();
  const navigate = useNavigate();

  function stableSort(array: Array<Patient>) {
    return array.sort((a: Patient, b: Patient) => {
      if (a.lastname < b.lastname) {
        return -1;
      }
      if (a.lastname > b.lastname) {
        return 1;
      }

      return 0;
    });
  }

  const getPatients = async () => {
    const patientsResponse = await UserService.getPatients();
    if (patientsResponse.error) {
      console.log(patientsResponse);
    }

    if (!patientsResponse.error) {
      const patientsData = stableSort(patientsResponse.data);

      setInitialPatients(patientsData);
      setPatients(patientsData);
    }
  };

  useEffect(() => {
    setLoading(true);
    getPatients().then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setNumberOfActivePatients(initialPatients.filter((p) => p.active).length);
  }, [patients]);

  const search = (input: string) => {
    setPatients(
      initialPatients.filter(
        (item) => item.lastname.toLowerCase().includes(input) || item.firstname.toLowerCase().includes(input)
      )
    );
  };

  useEffect(() => {
    setPatients(initialPatients.filter((x) => x.lastname.toLowerCase().startsWith(letter)));
  }, [letter]);

  const pageClasses = cx('container');
  const MainDiv = cx({ PatientsMainDiv: true });
  const TableContainerClasses = cx({ PatientsTableContainer: true });
  const AlphabetButton = cx({ PatientsAlphabetButton: true });
  const InputClasses = cx({ PatientsInput: true });

  function formatDate(dateString: string) {
    const date = new Date(dateString);

    return [
      date.getDate().toString().padStart(2, '0'),
      (date.getMonth() + 1).toString().padStart(2, '0'),
      date.getFullYear(),
    ].join('.');
  }

  return (
    <div className={pageClasses}>
      <NavigationDrawer />
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} className={MainDiv}>
          {loading && <Loader />}
          <PatientsHeader activePatientsNumber={numberOfActivePatients} patientsNumber={initialPatients.length} />
          <Grid container>
            <Grid item xs={12} sm={6} md={8} lg={9} xl={7}>
              {alphabet.split('').map((c, i) => (
                <Button
                  key={i}
                  className={AlphabetButton}
                  onClick={() => (letter === c ? setLetter('') : setLetter(c))}
                  type={letter === c ? 'secondary' : 'primary'}
                >
                  {c}
                </Button>
              ))}
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Input
                type='search'
                placeholder='Patient suchen...'
                inputMode='search'
                onChange={(e) => search(e.target.value.toLowerCase())}
                icon='magnify'
                className={InputClasses}
              />
            </Grid>
          </Grid>
          <TableContainer className={TableContainerClasses}>
            <Table size='medium'>
              <TableBody>
                {patients.map((row, index) => {
                  return (
                    <>
                      {(index === 0 || row.lastname[0] !== patients[index - 1].lastname[0]) && (
                        <TableRow style={{ border: 'none' }}>
                          <TableCell className={style.PatientsTableCellHeaderMain}>
                            {row.lastname[0].toUpperCase()}
                          </TableCell>
                          <TableCell className={style.PatientsTableCellHeader}>STATUS</TableCell>
                          <TableCell className={style.PatientsTableCellHeader}>TRACKING</TableCell>
                          <TableCell className={style.PatientsTableCellHeader}>LETZTE TERMIN</TableCell>
                        </TableRow>
                      )}
                      <TableRow
                        hover
                        key={index}
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/patients/${row.id}`)}
                      >
                        <TableCell className={style.PatientsTableCell}>
                          <div style={{ display: 'flex' }}>
                            <div style={{ width: 50, paddingRight: 20 }}>ID: {row.id}</div>
                            <div style={{ width: 250 }}>
                              {row.lastname}, {row.firstname}
                            </div>
                            {formatDate(row.birthday)}
                          </div>
                        </TableCell>
                        {row.active ? (
                          <TableCell className={style.PatientsTableCellGreen}>aktiv</TableCell>
                        ) : (
                          <TableCell className={style.PatientsTableCellRed}>inaktiv</TableCell>
                        )}
                        {row.isPatientTracking ? (
                          <TableCell className={style.PatientsTableCellGreen}>aktiv</TableCell>
                        ) : (
                          <TableCell className={style.PatientsTableCellRed}>inaktiv</TableCell>
                        )}
                        {row.lastAppointment ? (
                          <TableCell className={style.PatientsTableCell}>
                            vor &nbsp;
                            {Math.ceil(
                              (today.getTime() - Date.parse(row.lastAppointment.startDate)) / (1000 * 3600 * 24)
                            )}
                            d
                          </TableCell>
                        ) : (
                          <TableCell className={style.PatientsTableCell}>-</TableCell>
                        )}
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}
