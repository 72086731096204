import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { UserService } from '../../../../services';
import type { Medication as MedicationType } from '../../../../types';
import { Button, Icon, InputField } from '../../../atoms';
import { Dialog } from './Dialog';
import style from './Medication.module.scss';

const cx = classNames.bind(style);
const GreenHeading = cx('GreenHeading');
const TextLabel = cx('TextLabel');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function Medication({ currentMedication, setCurrentMedication, preparation }: any): JSX.Element {
  const params = useParams();
  const pageClasses = cx({ Medication: true });

  const [showDialog, setShowDialog] = useState(false);
  const [medicationToDelete, setMedicationToDelete] = useState<number>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validate = (data: any) => {
    let error = false;

    data.preparationListItem.forEach((element: MedicationType) => {
      const { preparationId, name, dosageUnit, quantity, dosageSingle, dosageMax } = element;

      if (preparationId === '') {
        error = true;
      }
      if (name === '') {
        error = true;
      }
      if (dosageUnit === '') {
        error = true;
      }
      if (quantity === '' || Number(quantity) <= 0) {
        error = true;
      }
      if (dosageSingle === '' || Number(dosageSingle) <= 0 || /^[0-9,.]*$/.test(dosageSingle) === false) {
        error = true;
      }
      if (dosageMax === '' || Number(dosageMax) <= 0 || /^[0-9,.]*$/.test(dosageMax) === false) {
        error = true;
      }
    });

    return error;
  };

  const generateUniqueID = async () => {
    const IDs = await currentMedication.map((element: { id: MedicationType }) => element.id);
    let foundID = false;
    let ranNum;

    while (foundID === false) {
      ranNum = Math.floor(Math.random() * (20 - 1)) + 1;
      if (!IDs.includes(ranNum)) {
        foundID = true;
      }
    }

    return ranNum;
  };

  const addMedicine = async () => {
    const newID = await generateUniqueID();
    setCurrentMedication((medication: MedicationType[]) => {
      return [
        ...medication,
        {
          id: newID,
          dosageMax: '',
          dosageSingle: '',
          dosageUnit: 'g',
          preparationId: '',
          preparationQuestion: [],
          quantity: '',
        },
      ];
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (id: any) => {
    setShowDialog(true);
    setMedicationToDelete(id);
  };

  const onConfirm = () => {
    setCurrentMedication((medication: MedicationType[]) => {
      const newArr = medication.filter((d: MedicationType) => d.id !== medicationToDelete);

      return newArr;
    });
    setShowDialog(false);
  };

  const onCancel = () => {
    setShowDialog(false);
  };

  const save = async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = {
      patientId: params.patientId,
      preparationListItem: [],
    };
    await currentMedication.forEach((element: MedicationType) => {
      const preparationID = preparation.filter((d: MedicationType) => element.name === d.name);
      const newObj = {
        preparationId: preparationID[0]?.id,
        dosageUnit: element.dosageUnit,
        quantity: element.quantity,
        dosageSingle: element.dosageSingle,
        dosageMax: element.dosageMax,
      };
      data.preparationListItem.push(newObj);
    });
    const newError = validate(data);
    if (newError) {
      toast.warn('Kontrollieren Sie Ihre Angaben');

      return;
    }
    const resp = await UserService.setMedication(data);
    if (resp.error) {
      toast.error('Etwas ist schiefgelaufen');
      console.log(resp.message);
    } else {
      toast.success('Medikation gespeichert!');
      sessionStorage.setItem('savedMedication', JSON.stringify(currentMedication));
      console.log(resp.data.message);
    }
  };

  return (
    <div className={pageClasses}>
      {showDialog && (
        <Dialog message={'Möchten Sie das Präparat wirklich entfernen?'} onConfirm={onConfirm} onCancel={onCancel} />
      )}
      <h1 className={GreenHeading}>AKTUELLE MEDIKATION</h1>
      <div className={style.MainContainer}>
        <div className={style.MedicineContainer}>
          {currentMedication &&
            currentMedication.map((element: { id: string; preparationId: string }, index: number) => {
              return (
                <div className={style.RowContainer} key={element.id}>
                  <div className={style.ColumnContainer}>
                    <p className={TextLabel}>Präparat</p>
                    <select
                      className={style.InputClass}
                      defaultValue={currentMedication[index].name || 'default'}
                      onChange={(event) => {
                        const newArr = currentMedication;
                        newArr[index].name = event.target.value;

                        setCurrentMedication(newArr);
                      }}
                    >
                      <option value={'default'} disabled></option>
                      {preparation &&
                        preparation.map((medicine: MedicationType) => {
                          return <option key={medicine.id}>{medicine.name}</option>;
                        })}
                    </select>
                  </div>
                  <div className={style.ColumnContainer}>
                    <p className={TextLabel}>Gesamtmenge</p>
                    <InputField
                      defaultValue={currentMedication[index].quantity}
                      type={'number'}
                      className={style.InputClass}
                      onChange={(event) => {
                        const newArr = currentMedication;
                        newArr[index].quantity = event.target.value;

                        setCurrentMedication(newArr);
                      }}
                    />
                  </div>
                  <div className={style.ColumnContainer}>
                    <p className={TextLabel}>TD</p>
                    <InputField
                      type={'decimal'}
                      inputMode={'decimal'}
                      defaultValue={currentMedication[index].dosageMax}
                      className={style.InputClass}
                      onChange={(event) => {
                        const newArr = currentMedication;
                        newArr[index].dosageMax = event.target.value;

                        setCurrentMedication(newArr);
                      }}
                    />
                  </div>
                  <div className={style.ColumnContainer}>
                    <p className={TextLabel}>ED</p>
                    <InputField
                      type={'decimal'}
                      inputMode={'decimal'}
                      className={style.InputClass}
                      defaultValue={currentMedication[index].dosageSingle}
                      onChange={(event) => {
                        const newArr = currentMedication;
                        newArr[index].dosageSingle = event.target.value;

                        setCurrentMedication(newArr);
                      }}
                    />
                  </div>
                  <div className={style.DosageUnitContainer}>
                    <p>Gramm (g)</p>
                  </div>
                  <div className={style.DeleteContainer} onClick={() => handleClick(element.id)}>
                    <Icon icon={'trash'} size={16} color={'rgb(220, 0, 0)'} />
                    <p className={style.DeleteText}>entfernen</p>
                  </div>
                </div>
              );
            })}
        </div>
        <div className={style.AddMedicineContainer}>
          <div className={style.AddMedicineButton} onClick={addMedicine}>
            <p className={style.PlusSymbol}>+</p>
            <p>Präparat hinzufügen</p>
          </div>
        </div>
      </div>
      <div className={style.ButtonContainer}>
        <Button children={'Änderungen speichern'} className={style.ButtonClass} type={'primary'} onClick={save} />
      </div>
    </div>
  );
}
