import { IconDefaults } from '../IconDefaults';
import type { IconProps } from '../IconProps';

export function Checklist({ size = IconDefaults.size, color = IconDefaults.color }: IconProps) {
  return (
    <svg
      id='Ebene_2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 71.959 67.135'
      height={size}
      width={size}
      fill={color}
    >
      <g id='Ebene_1-2'>
        <g id='Header_nowomed'>
          <g>
            <path d='M10.316,17.135c-.36,0-.72-.129-1.006-.387L.494,8.782c-.615-.555-.663-1.504-.107-2.119,.556-.615,1.504-.663,2.119-.107l7.81,7.057L24.953,.387c.615-.555,1.563-.507,2.119,.107,.555,.615,.507,1.563-.107,2.119l-15.643,14.135c-.286,.258-.646,.387-1.006,.387Z' />
            <path d='M70.459,10.5h-30c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5h30c.828,0,1.5,.672,1.5,1.5s-.672,1.5-1.5,1.5Z' />
            <path d='M10.316,42.135c-.36,0-.72-.129-1.006-.387L.494,33.782c-.615-.555-.663-1.504-.107-2.119,.556-.615,1.504-.663,2.119-.107l7.81,7.057,14.638-13.226c.615-.555,1.563-.507,2.119,.107,.555,.615,.507,1.563-.107,2.119l-15.643,14.135c-.286,.258-.646,.387-1.006,.387Z' />
            <path d='M70.459,35.5h-30c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5h30c.828,0,1.5,.672,1.5,1.5s-.672,1.5-1.5,1.5Z' />
            <path d='M10.316,67.135c-.36,0-.72-.129-1.006-.387L.494,58.782c-.615-.555-.663-1.504-.107-2.119,.556-.615,1.504-.663,2.119-.107l7.81,7.057,14.638-13.226c.615-.555,1.563-.507,2.119,.107,.555,.615,.507,1.563-.107,2.119l-15.643,14.135c-.286,.258-.646,.387-1.006,.387Z' />
            <path d='M70.459,60.5h-30c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5h30c.828,0,1.5,.672,1.5,1.5s-.672,1.5-1.5,1.5Z' />
          </g>
        </g>
      </g>
    </svg>
  );
}
