import classNames from 'classnames/bind';
import { Button } from '../Button/Button';
import style from './Dialog.module.scss';

const cx = classNames.bind(style);

type DialogProps = {
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
  type: string;
  buttonText: string;
};

export function Dialog({ message, onCancel, onConfirm, type, buttonText }: DialogProps): JSX.Element {
  const pageClasses = cx('container');
  const Container = cx({ DialogContainer: true });
  const InnerContainer = cx({ DialogInnerContainer: true });
  const Heading = cx({ DialogHeading: true });
  const ButtonsContainer = cx({ DialogButtonsContainer: true });
  const ConfirmButton = cx({ DialogConfirmButton: true });
  const CancelButton = cx({ DialogCancelButton: true });
  const NegativeButton = cx({ DialogNegativeButton: true });

  return (
    <div className={pageClasses}>
      <div className={Container}>
        <div className={InnerContainer}>
          <h6 className={Heading}>{message}</h6>
          <div className={ButtonsContainer}>
            <Button children={'Abbrechen'} className={CancelButton} type={'secondary'} onClick={onCancel} />
            {type === 'positiveDialog' ? (
              <Button children={buttonText} className={ConfirmButton} type={'primary'} onClick={onConfirm} />
            ) : (
              <Button children={buttonText} className={NegativeButton} type={'primary'} onClick={onConfirm} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
