import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import { UserService } from '../../../services';
import { Button, Icon } from '../../atoms';
import { Input } from '../../molecules';
import style from './ResetPassword.module.scss';

const cx = classNames.bind(style);

// Example to Save routes for only authenticated users
// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx

export function ResetPassword(): JSX.Element {
  const navigate = useNavigate();
  const token = useSearchParams()[0].get('token') || '';

  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const [error, setError] = useState({
    passwordReq: false,
    passwordEqual: false,
    emailTaken: false,
  });

  const validate = () => {
    const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/;
    const newError = {
      passwordReq: !regex.test(password) || password.length < 8,
      passwordEqual: password !== repeatedPassword,
    };
    setError({
      ...error,
      ...newError,
    });

    return newError;
  };

  const resetPassword = async () => {
    const newError = validate();
    if (Object.values(newError).find((e) => e)) {
      return;
    }

    const resp = await UserService.resetPassword({
      token,
      password,
    });
    if (!resp.error) {
      navigate('/login');
    }
    console.log(resp.message);
  };

  const ResetPasswordPageClasses = cx({ ResetPassword: true });
  const ResetPasswordBoxClasses = cx({ ResetPasswordBox: true });
  const LogoContainerClasses = cx('margin-10');
  const InputClasses = cx('margin-10');
  const ButtonClasses = cx('margin-10');

  return (
    <div className={ResetPasswordPageClasses}>
      <div className={ResetPasswordBoxClasses}>
        <div className={LogoContainerClasses}>
          <Icon icon='logo' size={160} />
        </div>
        <Input
          onChange={(event) => setPassword(event.target.value)}
          className={InputClasses}
          type='password'
          icon='magnify'
          placeholder='Passwort...'
          size={15}
        />
        <Input
          onChange={(event) => setRepeatedPassword(event.target.value)}
          className={InputClasses}
          type='password'
          icon='magnify'
          placeholder='Passwort wiederholen...'
          size={15}
        />
        <Button className={ButtonClasses} type='primary' onClick={resetPassword}>
          Passwort setzen
        </Button>
      </div>
    </div>
  );
}
