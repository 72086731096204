/* eslint-disable @typescript-eslint/no-explicit-any */
export function Dialog({ message, onCancel, onConfirm }: any): JSX.Element {
  return (
    <div
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: 'rgba(0,0,0,0.2)',
      }}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: '40%',
          left: '55%',
          transform: 'translate(-50%,-50%)',
          background: 'white',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <h3 style={{ color: '#111', fontSize: '16px' }}>{message}</h3>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button
            onClick={() => onConfirm()}
            style={{
              background: 'red',
              color: 'white',
              width: '50px',
              padding: '10px',
              marginRight: '4px',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            Ja
          </button>
          <button
            onClick={() => onCancel()}
            style={{
              background: 'green',
              color: 'white',
              width: '50px',
              padding: '10px',
              marginLeft: '10px',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            Nein
          </button>
        </div>
      </div>
    </div>
  );
}
