import type { ReactNode } from 'react';
import React from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { ProtectedPage } from '../components/atoms';
import { Calendar, Dashboard, Login, PasswordForgot, Patient, Patients, ResetPassword } from '../components/pages';

type RouterProps = {
  children?: ReactNode;
};
export function Router({ children }: RouterProps): JSX.Element {
  return (
    <BrowserRouter>
      {children}
      <Routes>
        <Route path='' element={<Navigate to='/dashboard' replace />} />
        <Route
          path='dashboard'
          element={
            <ProtectedPage>
              <Dashboard />
            </ProtectedPage>
          }
        />
        <Route
          path='patients'
          element={
            <ProtectedPage>
              <Outlet />
            </ProtectedPage>
          }
        >
          <Route path='' element={<Patients />} />
          <Route path=':patientId' element={<Patient />} />
          <Route path=':patientId/details' element={<Patient />} />
        </Route>
        <Route
          path='calendar'
          element={
            <ProtectedPage>
              <Calendar />
            </ProtectedPage>
          }
        />
        <Route path='login' element={<Login />} />
        <Route path='password-forgot' element={<PasswordForgot />} />
        <Route path='reset-password' element={<ResetPassword />} />
        <Route path='*' element={<h1>Actual no Page</h1>} />
      </Routes>
    </BrowserRouter>
  );
}
