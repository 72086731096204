import { useEffect, useState } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import { useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import 'moment/locale/de';
import classNames from 'classnames/bind';
import moment from 'moment';
import { UserService } from '../../../services';
import type { AppointmentCalendar as Appointment, Event } from '../../../types';
import { Loader } from '../../atoms';
import { NavigationDrawer } from '../../organisms';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import style from './Calendar.module.scss';

const cx = classNames.bind(style);

export function Calendar(): JSX.Element {
  const CalendarClasses = cx('container', { Calendar: true });
  const CalendarContainerClasses = cx({ CalendarContainer: true });
  const localizer = momentLocalizer(moment);
  const navigate = useNavigate();

  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState({ id: 113, open: true });

  const closeModal = (id: number) => {
    setOpenPopup({ id, open: false });
  };

  const PopupTermin = (event: Event) => {
    const PopupClasses = cx({ CalendarPopup: true });
    const NameClasses = cx({ CalendarPopupName: true });

    return (
      <Popup
        trigger={<div></div>}
        open={event.patientId === openPopup.id && openPopup.open}
        closeOnDocumentClick
        onClose={() => closeModal(event.patientId)}
        position='right center'
      >
        <div className={PopupClasses}>
          <div className={NameClasses}>{event.patientName}</div>
          Popup content here !!
        </div>
      </Popup>
    );
  };

  const getPatients = async () => {
    const appointmentsResponse = await UserService.getAppointments();
    if (appointmentsResponse.error) {
      console.log(appointmentsResponse);
    }

    if (!appointmentsResponse.error) {
      let eventsnew = [];
      eventsnew = appointmentsResponse.data.map((appointment: Appointment) => {
        const event = {
          start: new Date(appointment.startDate),
          end: new Date(appointment.endDate),
          patientName: appointment.patientName,
          patientId: appointment.patientId,
          kindTermin: appointment.type === 1 ? 'Wiedervorstellung' : 'Erstgespräch',
        };

        return event;
      });
      setEvents(eventsnew);
    }
  };

  type EventTypeDate = {
    event: Event;
  };

  const EventItem = ({ event }: EventTypeDate) => {
    const EventClasses = cx({ CalendarEvent: true });
    const PatientNameClasses = cx({ CalendarEventPatientName: true });

    return (
      <div className={EventClasses}>
        {PopupTermin(event)}
        <div
          className={PatientNameClasses}
          onClick={() => setOpenPopup({ id: event.patientId, open: true })}
          // onClick={() => navigate(`/patients/${event.patientId}`)}
        >
          {event.patientName}
        </div>
        <div>{event.kindTermin}</div>
      </div>
    );
  };

  useEffect(() => {
    getPatients().then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div className={CalendarClasses}>
      <NavigationDrawer />
      {loading && <Loader />}
      {!loading && (
        <div className={CalendarContainerClasses}>
          <BigCalendar
            localizer={localizer}
            events={events}
            view={'week'}
            views={['week']}
            style={{ height: '90vh' }}
            scrollToTime={moment().set({ h: 7, m: 0 }).toDate()}
            components={{
              event: EventItem,
            }}
            eventPropGetter={() => {
              return {
                style: { backgroundColor: '#3CB482' },
              };
            }}
            messages={{
              next: 'Weiter',
              previous: 'Zurück',
              today: 'Heute',
              month: 'Monat',
              week: 'Woche',
              day: 'Tag',
              agenda: 'Tagesordnung',
            }}
          />
        </div>
      )}
    </div>
  );
}
