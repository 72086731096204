import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { UserService } from '../../../services';
import type { DoctorAppointments } from '../../../types';
import { Button, Icon, Loader } from '../../atoms';
import { Input } from '../../molecules';
import { NavigationDrawer } from '../../organisms';
import style from './Dashboard.module.scss';

const cx = classNames.bind(style);

// Example to Save routes for only authenticated users
// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx

export function Dashboard(): JSX.Element {
  const DashboardClasses = cx('container', { Dashboard: true });

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDateAppointments, setSelectedDateAppointments] = useState<DoctorAppointments[]>([]);
  const [dateText, setDateText] = useState('');
  const [appointments, setAppointments] = useState<DoctorAppointments[]>([]);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const selectedAppointments = () => {
    const newArray = appointments.filter(
      (element: DoctorAppointments) =>
        new Date(element.startDate).toDateString() === new Date(selectedDate).toDateString()
    );
    setSelectedDateAppointments(newArray);
  };

  const getAppointments = async () => {
    const AppointmentsResponse = await UserService.getAppointments(true);

    if (AppointmentsResponse.error) {
      console.log(AppointmentsResponse);
    }
    if (AppointmentsResponse.data) {
      setAppointments(AppointmentsResponse.data);
    }
  };

  const getDoctorTasks = async () => {
    const DoctorTasksResponse = await UserService.getDoctorTasks();
    setTasks(DoctorTasksResponse.data);
  };

  const getDateText = () => {
    const date: string = new Date(selectedDate).toLocaleString('de-DE', {
      weekday: 'short',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    });

    const newString = date.slice(0, 2) + date.slice(3);
    setDateText(newString);
  };

  const nextDay = () => {
    const day = new Date(selectedDate);
    const newDay = day.setDate(day.getDate() + 1);
    setSelectedDate(new Date(newDay));
  };

  const prevDay = () => {
    const day = new Date(selectedDate);
    const newDay = day.setDate(day.getDate() - 1);

    setSelectedDate(new Date(newDay));
  };

  const checkMinForAppointment = (element: DoctorAppointments) => {
    const date = new Date(element.startDate);
    const today = new Date();

    if (date.toDateString() === today.toDateString()) {
      if (date > today) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const diffTime = Math.abs(date - today);
        const diffMin = diffTime / 60000;

        if (diffMin < 11) {
          return (
            <>
              <Icon icon={'clock'} size={19} color={'rgb(251, 176, 59)'} />
              <p>in {Math.trunc(diffMin)} Min.</p>
            </>
          );
        }
      }
    }

    return <></>;
  };

  const checkVideoButtonDisplay = (element: DoctorAppointments) => {
    const date = new Date(element.startDate);
    const today = new Date();

    if (element.type === 1) {
      if (date.toDateString() === new Date().toDateString()) {
        if (date > today) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const diffTime = Math.abs(date - today);
          const diffMin = diffTime / 60000;

          if (diffMin < 11) {
            return (
              <>
                <Button
                  children={'Zur Videosprechstunde'}
                  type={'primary'}
                  className={style.AppointmentsButtonClass}
                  onClick={() => {
                    window.open(`https://esprechstunde.net/termin/${element.esprechstundeCode}`, '_blank');
                  }}
                ></Button>
              </>
            );
          }
        }
      }
    }

    return <></>;
  };

  useEffect(() => {
    getAppointments().then(() => {
      getDoctorTasks();
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    getDateText();
    selectedAppointments();
  }, [selectedDate]);

  useEffect(() => {
    selectedAppointments();
  }, [loading]);

  return (
    <div className={DashboardClasses}>
      <NavigationDrawer />
      {loading && <Loader />}
      {!loading && (
        <div>
          <div className={style.MainContainer}>
            <div className={style.ColumnContainer}>
              <div className={style.AppointmentsTitleContainer}>
                <div style={{ position: 'relative' }}>
                  <Icon icon={'calendar'} size={80} />
                  <p className={style.CalendarDay}>
                    {new Date(selectedDate).toLocaleString('de-DE', {
                      day: '2-digit',
                    })}
                  </p>
                </div>
                <div>
                  <p className={style.MyApointmentsHeader}>Meine Termine</p>
                  <p className={style.DateHeader}>{dateText}</p>
                </div>
                <div className={style.RowContainer}>
                  <div>
                    <button className={style.ArrowButton} onClick={prevDay}>
                      <Icon icon={'chevron'} size={25} />
                    </button>
                  </div>
                  <div>
                    <button className={style.ArrowButton} onClick={nextDay}>
                      <Icon icon={'chevron'} size={25} />
                    </button>
                  </div>
                </div>
              </div>
              {selectedDateAppointments.map((element: DoctorAppointments, index: number) => {
                return (
                  <div className={style.AppointmentsContainers} key={index}>
                    <div>
                      <p className={style.AppointmentTimeNameText}>
                        {new Date(element.startDate).toLocaleString('de-DE', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                        {' Uhr'}
                      </p>
                      {element.type === 1 && <p className={style.AppointmentTypeText}>Online</p>}
                      {element.type === 2 && <p className={style.AppointmentTypeText}>Persönlich</p>}
                    </div>
                    <div>
                      <p className={style.AppointmentTimeNameText}>{element.patientName}</p>
                      {element.type === 1 && <p className={style.AppointmentText}>Wiedervorstellung</p>}
                      {element.type === 2 && <p className={style.AppointmentText}>Erstgespräch</p>}
                      {checkVideoButtonDisplay(element)}
                    </div>

                    <div className={style.ClockContainer}>{checkMinForAppointment(element)}</div>
                  </div>
                );
              })}
            </div>
            <div className={style.ColumnContainer}>
              <div className={style.TasksTitleContainer}>
                <Icon icon={'checklist'} size={78} />
                <div>
                  <p>Meine Aufgaben</p>
                </div>
                <div>
                  <Input
                    type='search'
                    placeholder='Patient suchen...'
                    inputMode='search'
                    icon='magnify'
                    className={style.searchInputClass}
                  />
                </div>
              </div>
              {tasks.map((element: DoctorAppointments, index: number) => {
                return (
                  <div className={style.TasksContainer} key={index}>
                    <div>
                      <p>{element.patientName}</p>
                    </div>
                    <div>
                      <p>Folgerezept angefordert</p>
                    </div>
                    <div>
                      <Button
                        children={'Details anzeigen'}
                        type={'primary'}
                        className={style.ButtonClass}
                        onClick={() => navigate(`/patients/${element.patientId}/details`)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
