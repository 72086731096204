import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { UserService } from '../../../../services';
import type { FollowRecipe as FollowRecipeType, Medication as MedicationType } from '../../../../types';
import { Button, InputField } from '../../../atoms';
import { Dialog } from '../../../atoms/Dialog/Dialog';
import style from './FollowRecipe.module.scss';

const cx = classNames.bind(style);

type PropsTypes = {
  currentMedication: MedicationType[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  followUpPrescriptionQuestion?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  followUpPrescriptionRequest?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  availableFollowUpPrescription?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setAvailableFollowUpPrescription: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPatient: any;
};

export function FollowRecipe({
  currentMedication,
  followUpPrescriptionQuestion,
  followUpPrescriptionRequest,
  availableFollowUpPrescription,
  setAvailableFollowUpPrescription,
  getPatient,
}: PropsTypes): JSX.Element {
  const params = useParams();
  const pageClasses = cx({ Recipe: true });
  const GreenHeading = cx('GreenHeading');
  const TextBold = cx('TextBold');
  const TextLabel = cx('TextLabel');
  const ColumnContainer = cx('ColumnContainer');

  const [doctorsFreeText, setDoctorsFreeText] = useState('');
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [showDeclineDialog, setShowDeclineDialog] = useState(false);
  const [followupRequestToShow, setFollowupRequestToShow] = useState<FollowRecipeType | undefined>();
  const [followupQuestionToShow, setFollowupQuestionToShow] = useState<FollowRecipeType | undefined>();

  const closeApproveDialog = () => setShowApproveDialog(false);
  const closeDeclineDialog = () => setShowDeclineDialog(false);

  const validate = () => {
    let error = false;

    if (
      availableFollowUpPrescription === '' ||
      Number(availableFollowUpPrescription) <= 0 ||
      /^[0-9,.]*$/.test(availableFollowUpPrescription) === false
    ) {
      error = true;
    }

    return error;
  };

  const saveFollowRecipes = async () => {
    const num = availableFollowUpPrescription;
    const data = { availableFollowUpPrescription: num };
    const newError = validate();

    if (newError) {
      toast.warn('Kontrollieren Sie Ihre Angaben');

      return;
    }

    const numberOfPrescription = await UserService.availableFollowupPrescription(Number(params.patientId), data);

    if (numberOfPrescription.error) {
      toast.error('Etwas ist schiefgelaufen');
    }

    if (numberOfPrescription.data) {
      sessionStorage.setItem('AvailableFollowUpPrescription', availableFollowUpPrescription);
      toast.success('Anzahl der Folgerezepte gespeichert');
    }
  };

  const decline = async () => {
    const data = {
      status: 'declined',
    };
    const declineRequest = await UserService.followupPrescriptionRequest(Number(followupRequestToShow?.id), data);

    if (declineRequest.error) {
      toast.error('Etwas ist schiefgelaufen');
    }

    if (declineRequest.data) {
      toast.success('Folgerezept abgelehnt ');
    }

    closeDeclineDialog();
    getPatient();
    setFollowupRequestToShow(undefined);
  };

  const approve = async () => {
    const data = {
      status: 'accepted',
      doctorsMessage: doctorsFreeText,
    };

    const approveRequest = await UserService.followupPrescriptionRequest(Number(followupRequestToShow?.id), data);

    if (approveRequest.error) {
      toast.error('Etwas ist schiefgelaufen');
    }

    if (approveRequest.data) {
      toast.success('Folgerezept genehmigt');
    }

    closeApproveDialog();
    getPatient();
    setFollowupRequestToShow(undefined);
  };

  useEffect(() => {
    if (followUpPrescriptionRequest && followUpPrescriptionRequest.length > 0) {
      const lastRequestIndex = followUpPrescriptionRequest.length - 1;
      const lastRequest = followUpPrescriptionRequest[lastRequestIndex];

      if (lastRequest.status === undefined || lastRequest.status === 1) {
        setFollowupRequestToShow(lastRequest);
        setFollowupQuestionToShow(followUpPrescriptionQuestion[lastRequestIndex]);
      }
    }
  }, [followUpPrescriptionRequest]);

  const generalSideEffectsLabels = (item: string) => {
    const germanText = item
      .replace('reactions', 'Allergische Reaktionen / Unverträglichkeit')
      .replace('dryMouth', 'Trockene Schleimhäute (trockener Mund / gerötete Augen)')
      .replace('appetite', 'Gesteigerter Appetit (als unangenehmer Effekt)')
      .replace('nausea', 'Übelkeit')
      .replace('increasedFatigue', 'Erhöhte Müdigkeit (als unangenehmer Effekt)')
      .replace('cardioVascularProblems', 'Herzkreislaufprobleme (Blutdruckabfall, Herzrasen, Schwindel, Unwohlsein)')
      .replace('motorLimitations', 'Motorische Einschränkungen');

    const array = germanText.split(',');
    const finalText = array.join(', ');

    return finalText;
  };

  const psychologicalSideEffectsLabels = (item: string) => {
    const germanText = item
      .replace('alteredPerception', 'Veränderte Wahrnehmung / Bewusstseinsveränderung')
      .replace('thoughtDisorders', 'Denkstörungen / Gedächtnisstörungen')

      .replace('anxiety', 'Angst und Paniksituationen')
      .replace('hallucinations', 'Halluzinationen / Psychosen')
      .replace('toleranceDevelopment', 'Starke Toleranzentwicklung');
    const array = germanText.split(',');
    const finalText = array.join(', ');

    return finalText;
  };

  return (
    <div className={pageClasses}>
      {showApproveDialog && (
        <Dialog
          message={
            'Wollen Sie den Folgerezeptantrag wirklich genehmigen? Der Folgerezeptantrag schließt sich im Anschluss und kann nicht mehr verändert werden.'
          }
          type='positiveDialog'
          buttonText='Genehmigen'
          onConfirm={approve}
          onCancel={closeApproveDialog}
        />
      )}
      {showDeclineDialog && (
        <Dialog
          message={
            'Wollen Sie den Folgerezeptantrag wirklich ablehnen? Der Folgerezeptantrag schließt sich im Anschluss und kann nicht mehr verändert werden.'
          }
          type='negativeDialog'
          buttonText='Ablehnen'
          onConfirm={decline}
          onCancel={closeDeclineDialog}
        />
      )}
      <div className={style.MainContainer}>
        <div className={ColumnContainer}>
          <h1 className={GreenHeading}>ALLGEMEIN</h1>
          <div className={style.FirstContainer}>
            <div className={style.GeneraInfoContainer}>
              <p className={TextLabel}>Patient freigegeben für noch</p>
              <InputField
                className={style.InputClass}
                value={availableFollowUpPrescription}
                onChange={(event) => {
                  setAvailableFollowUpPrescription(Number(event.target.value));
                }}
              />
              <p className={TextLabel}>Folgerezepte</p>
              <Button
                children={'Speichern'}
                className={style.ButtonClass}
                type={'primary'}
                onClick={saveFollowRecipes}
              />
            </div>
          </div>
          <h1 className={GreenHeading}>ANTRAG</h1>
          {followupRequestToShow ? (
            <>
              <div className={style.Container}>
                <div className={style.MedicineContainer}>
                  <p className={TextLabel}>Antrag eingereicht am</p>
                  <p className={TextBold}>
                    {new Date(
                      followUpPrescriptionRequest[followUpPrescriptionRequest.length - 1].requestDate
                    ).toLocaleDateString('de-DE', {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                    {' Uhr'}
                  </p>
                </div>
                <div className={style.MedicineContainer}>
                  <p className={TextLabel}>Verschriebene Medikation reicht für etwa 30 Tage</p>
                  {followupQuestionToShow?.isEnough && <p className={TextBold}>Ja</p>}
                  {!followupQuestionToShow?.isEnough && <p className={TextBold}>Nein</p>}
                </div>
                <div className={style.MedicinesContainer}>
                  {currentMedication &&
                    currentMedication.map((element: MedicationType) => {
                      return (
                        <div key={element.preparationId}>
                          <h2>{element.name}</h2>
                          <div className={style.MedicineContainer}>
                            <p className={TextLabel}>Maximalemenge pro Tag</p>
                            <p className={TextBold}>{`${element.quantity} ${element.dosageUnit}`}</p>
                          </div>
                          <div className={style.MedicineContainer}>
                            <p className={TextLabel}>Maximale Einzelndosis</p>
                            <p className={TextBold}>{`${element.dosageSingle} ${element.dosageUnit}`}</p>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div>
                  <div className={style.MedicineContainer}>
                    <p className={TextLabel}>Allgemeine Nebenwirkungen</p>
                    <p className={TextBold}>{followupQuestionToShow?.generalSideEffects}</p>
                  </div>
                  <div className={style.MedicineContainer}>
                    <p className={TextLabel}>Psychische Nebenwirkungen</p>
                    <p className={TextBold}>{followupQuestionToShow?.psychologicalSideEffects}</p>
                  </div>
                </div>
              </div>
              <div className={style.WhiteBoxContainer}>
                <div className={style.MedicineContainer}>
                  <p className={style.BlackHeadings}>Neue Medikamente</p>
                  <p className={TextBold}>{followupQuestionToShow?.newMedicines || 'Nein'}</p>
                </div>
                <div className={style.MedicineContainer}>
                  <p className={style.BlackHeadings}>Medizinische Neuigkeiten</p>
                  <p className={TextBold}>{followupQuestionToShow?.medicalNews || 'Nein'}</p>
                </div>
                <div className={style.MedicineContainer}>
                  <p className={TextLabel}>Neue Apotheke</p>
                  {followupQuestionToShow?.isSamePharmacy ? (
                    <p className={TextBold}>Nein</p>
                  ) : (
                    <p className={TextBold}>{`Ja: ${followupQuestionToShow?.newPharmacy}
                    }`}</p>
                  )}
                </div>
                <div>
                  <h2 className={style.BlackHeadings}>Austauschpräparat in gleicher Dosierung</h2>
                  <p className={TextBold}>{followupQuestionToShow?.exchangePreparation || 'Keine'}</p>
                </div>
                <div>
                  <p className={TextLabel}>
                    Ihre Nachricht an den / die Patient*in (Die Nachricht wird wird Teil einer automatisierten E-Mail.
                    Füllen Sie diese bitte nur bei Genehmigung aus. Eine Anrede und Verabschiedung werden ebenfalls
                    automatisiert hinzugefügt).
                  </p>
                  <textarea
                    className={style.InputFreeText}
                    value={doctorsFreeText}
                    onChange={(event) => {
                      setDoctorsFreeText(event.target.value);
                    }}
                  ></textarea>
                </div>
                <div>
                  <div className={style.GreyText}>
                    <p>Genehmigen Sie den Antrag erst, nachdem die Verschreibung erstellt wurde.</p>
                  </div>
                  <div className={style.ButtonsContainer}>
                    <Button
                      children={'Antrag ablehnen'}
                      className={style.RedButton}
                      type={'secondary'}
                      onClick={() => setShowDeclineDialog(true)}
                    />
                    <Button
                      children={'Antrag genehmigen'}
                      className={style.GreenButton}
                      type={'primary'}
                      onClick={() => setShowApproveDialog(true)}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className={style.Container}>
              <p className={TextLabel}>Es liegt kein Antrag vor.</p>
            </div>
          )}
        </div>
        {currentMedication.length !== 0 && (
          <div>
            <h1 className={GreenHeading}>AKTUELLE MEDIKATION</h1>
            <div className={style.MedicationContainer}>
              {currentMedication &&
                currentMedication.map((element: MedicationType) => {
                  return (
                    <div className={style.RowContainer} key={element.id}>
                      <p className={style.MedicineText}>{element.name}</p>
                      <p className={TextLabel}>{`${element.quantity} ${element.dosageUnit}`}</p>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
