import { useEffect } from 'react';
import classNames from 'classnames/bind';
import type { DoctorsComments as DoctorsCommentsTypes, PatientDocuments as DocumentTypes } from '../../../../types';
import style from './Record.module.scss';

const cx = classNames.bind(style);

type RecordTypes = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patient: any;
  mainDiseaseLabels: string;
  documents: DocumentTypes[];
  doctorsComments: DoctorsCommentsTypes[];
};

export function Record({ patient, mainDiseaseLabels, documents, doctorsComments }: RecordTypes): JSX.Element {
  const pageClasses = cx({ Record: true });
  const GreenHeading = cx('GreenHeading');
  const TextLabel = cx('TextLabel');

  const previousDoctorsLabels = (labels: string) => {
    if (!labels) {
      return [];
    }
    const germanLabels = labels
      .replace('familyDoctor', 'Hausärzte / Hausärztinnen')
      .replace('alternativePractitioner', 'Heilpraktier:innen')
      .replace('orthopedist', 'Orthopäden / Orthopädinnen')
      .replace('neurologist', 'Neurologen / Neurologinnen')
      .replace('gynecologist', 'Gynäkologen / Gynäkologinnen')
      .replace('eyeSpecialist', 'Augenheilkundler:innen')
      .replace('psychotherapist', 'Psychotherapeuten / Psychotherapeutinnen')
      .replace('internist', 'Internisten / Internistinnen')
      .replace('anesthesiologist', 'Anästhesisten / Anästhesistinnen')
      .replace('dermatologist', 'Dermatologen / Dermatologinnen')
      .replace('otorhinolaryngologist', 'HNO-Ärzte / HNO-Ärztinnen')
      .replace('chiropractor', 'Chiropraktiker:innen')
      .replace('surgeon', 'Chirurgen / Chirurginnen')
      .replace('psychiatrist', 'Psychiater:innen')
      .replace('urologist', 'Urologen / Urologinnen')
      .replace('radiologist', 'Radiologen / Radiologinnen');
    const array = germanLabels.split(',');
    const removeWhiteSpace = array.map((element: string) => {
      return element.replace(/^\s+|\s+$/gm, '');
    });

    const FinalArray = removeWhiteSpace.map((element: string) => {
      return element;
    });

    return FinalArray;
  };

  const TreatmentMethodsLabels = (labels: string) => {
    const germanLabels = labels
      .replace('none', 'Keine')
      .replace('injection', 'Injektionen')
      .replace('acupuncture', 'Akupunktur')
      .replace('meditation', 'Meditation')
      .replace('cure', 'Kur')
      .replace('baths', 'Bäder')
      .replace('physiotherapy', 'Krankengymnastik')
      .replace('psychotherapy', 'Psychotherapie')
      .replace('hypnosis', 'Hypnose')
      .replace('massages', 'Massagen')
      .replace('homeopathy', 'Homöopathie')
      .replace('medication', 'Medikamente');
    const array = germanLabels.split(',');
    const removeWhiteSpace = array.map((element: string) => {
      return element.replace(/^\s+|\s+$/gm, '');
    });

    const FinalArray = removeWhiteSpace.map((element: string) => {
      return element;
    });

    return FinalArray;
  };

  const otherDiseasesLabels = () => {
    const data = [];
    if (patient.secondaryDiagnosis.movementDiseases) {
      data.push(...patient.secondaryDiagnosis.movementDiseases.split(','));
    }
    if (patient.secondaryDiagnosis.movementDiseasesOther) {
      data.push(...patient.secondaryDiagnosis.movementDiseasesOther.split(','));
    }
    if (patient.secondaryDiagnosis.respiratoryDiseases) {
      data.push(...patient.secondaryDiagnosis.respiratoryDiseases.split(','));
    }
    if (patient.secondaryDiagnosis.respiratoryDiseasesOther) {
      data.push(...patient.secondaryDiagnosis.respiratoryDiseasesOther.split(','));
    }

    if (patient.secondaryDiagnosis.cardiovascularDiseases) {
      data.push(...patient.secondaryDiagnosis.cardiovascularDiseases.split(','));
    }
    if (patient.secondaryDiagnosis.cardiovascularDiseasesOther) {
      data.push(...patient.secondaryDiagnosis.cardiovascularDiseasesOther.split(','));
    }
    if (patient.secondaryDiagnosis.gastrointestinalDiseases) {
      data.push(...patient.secondaryDiagnosis.gastrointestinalDiseases.split(','));
    }
    if (patient.secondaryDiagnosis.gastrointestinalDiseasesOther) {
      data.push(...patient.secondaryDiagnosis.gastrointestinalDiseasesOther.split(','));
    }
    if (patient.secondaryDiagnosis.neurologicalDiseases) {
      data.push(...patient.secondaryDiagnosis.neurologicalDiseases.split(','));
    }
    if (patient.secondaryDiagnosis.neurologicalDiseasesOther) {
      data.push(...patient.secondaryDiagnosis.neurologicalDiseasesOther.split(','));
    }
    if (patient.secondaryDiagnosis.psychiatricDiseases) {
      data.push(...patient.secondaryDiagnosis.psychiatricDiseases.split(','));
    }
    if (patient.secondaryDiagnosis.infectiousDiseases) {
      data.push(...patient.secondaryDiagnosis.infectiousDiseases.split(','));
    }
    if (patient.secondaryDiagnosis.infectiousDiseasesOther) {
      data.push(...patient.secondaryDiagnosis.infectiousDiseasesOther.split(','));
    }
    if (patient.secondaryDiagnosis.dermatology) {
      data.push(...patient.secondaryDiagnosis.dermatology.split(','));
    }
    if (patient.secondaryDiagnosis.dermatologyOther) {
      data.push(...patient.secondaryDiagnosis.dermatologyOther.split(','));
    }

    const germanLabels = data.map((element) => {
      return element
        .replace('acneInversa', 'Akna Inversa')
        .replace('hyperhidrosis', 'Hyperhidrose (übermäßiges Schwitzen)')
        .replace('psoriasis', 'Psoriasis (Schuppenflechte)')
        .replace('neurodermatitis', 'Neurodermitis')
        .replace('urticaria', 'Utrikaria (Nesselsucht)')
        .replace('pruritus', 'Pruritus (Juckreiz)')
        .replace('osteoarthritis', 'Arthrose')
        .replace('gout', 'Gicht')
        .replace('rheumatoidArthritis', 'rheumatoide Arthritis')
        .replace('fibromyalgia', 'Fibromyalgie')
        .replace('bronchialAsthma', 'Asthma bronchiale')
        .replace('copd', 'COPD (chronisch-obstruktive Lungenerkrankung')
        .replace('pneumonia', 'Lungenentzündung in den letzten 12 Monaten')
        .replace('highBloodPressure', 'Bluthochdruck')
        .replace('heartFailure', 'Herzinsuffizienz')
        .replace('heartAttack', 'Herzinfarkt oder Schlaganfall')
        .replace('bloodClottingDisorder', 'Blutgerinnungsstörung')
        .replace('cardiacArrhythmia', 'Herzrythmusstörung')
        .replace('coronaryHeartDisease', 'koronare Herzkrankheit (KHK)')
        .replace('thrombosis', 'Thrombose oder Embolie')
        .replace('crohnsDisease', 'orbus Crohn')
        .replace('irritableBowelSyndrome', 'Reizdarmsyndrom')
        .replace('cachexia', 'Kachexie (Abmagerung)')
        .replace('metabolicDisorders', 'Stoffwechselstörungen')
        .replace('ulcerativeColitis', 'Colitis ulcerosa')
        .replace('gastritis', 'Magenschleimhautentzündung (Gastritis)')
        .replace('diabetesMellitus', 'Diabetes mellitus')
        .replace('chronicHeadache', 'chronische Kopfschmerzen')
        .replace('epilepsy', 'Epilepsie / Krampfanfälle')
        .replace('tourettesSyndrome', 'Tourette-Syndrom')
        .replace('parkinsonsDisease', 'Morbus Parkinson')
        .replace('paraplegia', 'Paraplegie/Lähmungserscheinungen')
        .replace('migraine', 'Migräne')
        .replace('multipleSclerosis', 'multipleSclerosis')
        .replace('adhs', 'AD(H)S')
        .replace('polyneuropathy', 'Polyneuropathie')
        .replace('anxeityDisorder', 'Angststörungen')
        .replace('adjustmentDisorder', 'Anpassungsstörung')
        .replace('depression', 'Depressionen')
        .replace('postTraumaticStressDisorder', 'posttraumatische Belastungsstörung (PTBS)')
        .replace('psychoses', 'Psychosen')
        .replace('psychosesFamily', 'Psychosen innerhalb der Verwandschaft')
        .replace('sleepDisorder', 'Schlafstörungen')
        .replace('addiction', 'Suchterkrankung')
        .replace('hepatits', 'Hepatitis')
        .replace('tuberculosis', 'Tuberkulose')
        .replace('aids', 'HIV / AIDS')
        .replace('lymeDisease', 'Borreliose');
    });

    return germanLabels;
  };

  const gynecologicalAnamnesisLabels = (labels: string) => {
    const germanLabels = labels
      .replace('pregnancy', 'Schwangerschaft')
      .replace('pregnancyInPlanning', 'Schwangerschaft in Planung')
      .replace('breastfeed', 'Stillen')
      .replace('endometriosis', 'Endometriose')
      .replace('bladderSpasms', 'Blasenkrämpfe');
    const array = germanLabels.split(', ');
    const indexOfOther = germanLabels.indexOf('other');
    array.splice(indexOfOther, 1);

    const FinalArray = array.filter((element: string) => element !== 'other');

    return FinalArray;
  };

  const createImg = (file: string, name: string, thumbnail: string) => {
    let url: string;
    if (name.endsWith('.pdf')) {
      url = `data:image/jpeg;base64,${thumbnail}`;
    } else {
      url = `data:image/jpeg;base64,${file}`;
    }

    return (
      <>
        <img src={url} className={style.imgClass} />;
      </>
    );
  };

  function base64ToBlob(base64: string) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], { type: 'application/pdf' });
  }

  const createLink = (file: string, name: string) => {
    return (
      <>
        <div
          onClick={() => {
            const image = new Image();
            if (name.endsWith('.pdf')) {
              const pdf = new Blob([base64ToBlob(file)], { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(pdf);
              window.open(fileURL);
            } else {
              image.src = `data:image/jpeg;base64,${file}`;
            }

            const w = window.open('');
            if (w) {
              w.document.write(image.outerHTML);
            }
          }}
        >
          <p className={style.showImgText}>Anzeigen</p>
        </div>
      </>
    );
  };

  useEffect(() => {
    otherDiseasesLabels();
  }, []);

  return (
    <div className={pageClasses}>
      <div className={style.MainContainer}>
        <div>
          <h1 className={GreenHeading}>Persönliche daten</h1>
          <div className={style.personalDataContainer}>
            <div>
              <div>
                <p className={style.PersonalDataTextLabel}>Nachname</p>
                <p className={style.PersonalDataTextBold}>{patient?.lastname}</p>
              </div>
              <div>
                <p className={style.PersonalDataTextLabel}>Vorname</p>
                <p className={style.PersonalDataTextBold}>{patient?.firstname}</p>
              </div>
              <div>
                <p className={style.PersonalDataTextLabel}>Geburtsdatum</p>
                <p className={style.PersonalDataTextBold}>
                  {new Date(patient?.birthday)
                    .toLocaleString('de-DE', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })
                    .replace('-', '.')}
                </p>
              </div>
              {patient.mobile !== '' && (
                <div>
                  <p className={style.PersonalDataTextLabel}>Telefon (mobil)</p>
                  <p className={style.PersonalDataTextBold}>{patient?.mobile}</p>
                </div>
              )}
              {patient.landline !== '' && (
                <div>
                  <p className={style.PersonalDataTextLabel}>Telefon (Festnetz)</p>
                  <p className={style.PersonalDataTextBold}>{patient?.landline}</p>
                </div>
              )}
              <div>
                <p className={style.PersonalDataTextLabel}>E-Mail</p>
                <p className={style.PersonalDataTextBold}>{patient?.email}</p>
              </div>
            </div>
            <div>
              <div className={style.ColumnContainer}>
                <p className={style.PersonalDataTextLabel}>Geschlecht</p>

                {patient?.sex === 'male' && <p className={style.PersonalDataTextBold}>männlich</p>}
                {patient?.sex === 'female' && <p className={style.PersonalDataTextBold}>weiblich</p>}
                {patient?.sex === 'other' && <p className={style.PersonalDataTextBold}>andere</p>}
              </div>
              {patient.title && (
                <div>
                  <p className={style.PersonalDataTextLabel}>Titel</p>
                  <p className={style.PersonalDataTextBold}>
                    {patient.title.replace('doctor', 'Dr.').replace('professor', 'Prof.')}
                  </p>
                </div>
              )}
              <div>
                <p className={style.PersonalDataTextLabel}>Sprache</p>
                <p className={style.PersonalDataTextBold}>
                  {patient.language && patient.language.replace('german', 'deutsch').replace('english', 'englisch')}
                </p>
              </div>
              <div>
                <p className={style.PersonalDataTextLabel}>Nationalität</p>
                <p className={style.PersonalDataTextBold}>
                  {patient.nationality &&
                    patient.nationality
                      .replace('german', 'deutsch')
                      .replace('austrian', 'österreichisch')
                      .replace('swiss', 'schweiz')
                      .replace('belgian', 'belgisch')
                      .replace('danish', 'dänisch')
                      .replace('finnish', 'finnisch')
                      .replace('french', 'französisch')
                      .replace('greek', 'griechisch')
                      .replace('italian', 'italienisch')
                      .replace('dutch', 'niederländisch')
                      .replace('norwegian', 'norwegisch')
                      .replace('polish', 'polnisch')
                      .replace('portuguese', 'portugiesisch')
                      .replace('swedish', 'schwedisch')
                      .replace('spanish', 'spanisch')
                      .replace('czech', 'tschechisch')
                      .replace('turkish', 'türkisch')
                      .replace('american', 'US-amerikanisch')
                      .replace('british', 'britisch')}
                </p>
              </div>
              <div>
                <p className={style.PersonalDataTextLabel}>Versicherung</p>
                <p className={style.PersonalDataTextBold}>
                  {patient?.insurance &&
                    patient?.insurance
                      .replace('private', 'privat')
                      .replace('statutory', 'gesetzlich')
                      .replace('none', 'keine')}
                </p>
              </div>
              <div>
                <p className={style.PersonalDataTextLabel}>Größe</p>
                <p className={style.PersonalDataTextBold}>{`${patient?.size} cm`}</p>
              </div>
              <div>
                <p className={style.PersonalDataTextLabel}>Gewicht</p>
                <p className={style.PersonalDataTextBold}>{`${patient?.weight} kg`}</p>
              </div>
            </div>
          </div>
        </div>
        {doctorsComments && doctorsComments.length !== 0 && (
          <div>
            <h1 className={GreenHeading}>Kommentar für die behandelnden Ärzte</h1>
            {doctorsComments.map((element: DoctorsCommentsTypes, index: number) => {
              return (
                <div className={style.RemarkTextContainer} key={index}>
                  <p className={TextLabel}>
                    {new Date(element.createdAt)
                      .toLocaleString('de-DE', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      })
                      .replace('-', '.')}
                  </p>
                  <p className={style.FreeText}>{element.text}</p>
                </div>
              );
            })}
          </div>
        )}
        <div>
          <div className={style.SicknessMedicationContainer}>
            <div>
              <h1 className={GreenHeading}>Erkrankung</h1>
              <div className={style.SicknessContainer}>
                <div className={style.SicknessRowContainer}>
                  <p className={TextLabel}>Beschwerden</p>
                  <div>
                    <ul className={style.ListItems}>
                      {mainDiseaseLabels.split(',').map((element: string, index: number) => {
                        return (
                          <li className={TextLabel} key={index}>
                            {element}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className={style.SicknessRowContainer}>
                  <p className={TextLabel}>Erkrankungsbezeichnung</p>
                  <p className={TextLabel}>{patient?.firstPresentation.designation}</p>
                </div>
                <div className={style.SicknessRowContainer}>
                  <p className={TextLabel}>Erkrankt seit</p>
                  <p className={TextLabel}>
                    {patient?.firstPresentation.sinceDisease &&
                      patient?.firstPresentation.sinceDisease &&
                      new Date(patient?.firstPresentation.sinceDisease)
                        .toLocaleString('de-DE', {
                          month: 'long',
                          year: 'numeric',
                        })
                        .replace('-', '.')}
                  </p>
                </div>
                <div className={style.SicknessRowContainer}>
                  <p className={TextLabel}>Ärtztliche Diagnose</p>
                  <p className={TextLabel}>{patient?.firstPresentation.hasMainDiagnosis ? 'ja' : 'nein'}</p>
                </div>
                <div className={style.SicknessRowContainer}>
                  <p className={TextLabel}>Wegen Symptomatik aufgesucht</p>

                  <div className={style.ColumnContainer}>
                    <ul className={style.ListItems}>
                      {previousDoctorsLabels(patient.firstPresentation.previousDoctors)
                        .filter((element) => {
                          if (element === 'other') {
                            return false;
                          }

                          return true;
                        })
                        .map((element, index) => {
                          return (
                            <li className={TextLabel} key={index}>
                              {element}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div className={style.SicknessRowContainer}>
                  <p className={TextLabel}>Häufigkeit der Beschwerden</p>
                  <p className={TextLabel}>
                    {patient.firstPresentation.frequentComplaints
                      .replace('permanent', 'Dauernd')
                      .replace('daily', 'Täglich')
                      .replace('weekly', 'Wöchentlich')
                      .replace('monthly', 'Monatlich')
                      .replace('lessFrequently', 'Seltener')}
                  </p>
                </div>
                <div className={style.SicknessRowContainer}>
                  <p className={TextLabel}>Verwandte leiden an ähnlichen Erkrankungen</p>
                  <p className={TextLabel}>{patient?.firstPresentation.relatives ? 'ja' : 'nein'}</p>
                </div>
                <div className={style.SicknessRowContainer}>
                  <p className={TextLabel}>Stationär behandelt</p>
                  <p className={TextLabel}>{patient?.therapy.hasOperations?.hasInpatientTreatments ? 'ja' : 'nein'}</p>
                </div>
                <div className={style.SicknessRowContainer}>
                  <p className={TextLabel}>Operiert</p>
                  <p className={TextLabel}>{patient?.therapy?.hasOperations ? 'ja' : 'nein'}</p>
                </div>
                <div className={style.SicknessRowContainer}>
                  <p className={TextLabel}>Versuchte Behandlungsmethoden</p>

                  {patient.therapy.otherTreatmentMethods && (
                    <div className={style.ColumnContainer}>
                      <ul className={style.ListItems}>
                        {TreatmentMethodsLabels(patient.therapy.otherTreatmentMethods)
                          .filter((element) => {
                            if (element === 'other') {
                              return false;
                            }

                            return true;
                          })
                          .map((element, index) => {
                            return (
                              <li className={TextLabel} key={index}>
                                {element}
                              </li>
                            );
                          })}

                        {patient.therapy.otherTreatmentMethodsOther && (
                          <li className={TextLabel}>{patient.therapy.otherTreatmentMethodsOther}</li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <h1 className={GreenHeading}>Anschrift</h1>
              <div className={style.AddressContainer}>
                <div className={style.ColumnContainer}>
                  <p className={TextLabel}>Anschrift</p>
                </div>

                <div>
                  <p className={TextLabel}>
                    {patient?.address[0].street} {patient?.address[0].houseNumber}
                  </p>
                  <p className={TextLabel}>
                    {patient?.address[0].postcode} {patient?.address[0].city}
                  </p>
                  <p className={TextLabel}>
                    {patient?.address[0].country
                      .replace('germany', 'Deutschland')
                      .replace('switzerland', 'Schweiz')
                      .replace('poland', 'Polen')
                      .replace('denmark', 'Dänemark')}
                  </p>
                </div>
              </div>
              <h1 className={GreenHeading}>Medikation</h1>
              <div className={style.MedicationContainer}>
                <div className={style.MedicationRowContainer}>
                  <p className={TextLabel}>Medikamente wegen Haupterkrankung</p>
                  <p className={TextLabel}>
                    {patient.therapy.takeOtherMedicines ? `${patient.therapy.otherMedicines}` : 'nein'}
                  </p>
                </div>
                <div className={style.MedicationRowContainer}>
                  <p className={TextLabel}>Medikamente wegen anderer Erkrankung</p>
                  <p className={TextLabel}>
                    {patient.therapy.takeOtherMedicinesOtherDiseases
                      ? `${patient.therapy.otherMedicinesOtherDiseases}`
                      : 'nein'}
                  </p>
                </div>
                <div className={style.MedicationRowContainer}>
                  <p className={TextLabel}>Wirksamkeit der Medikamente</p>
                  <p className={TextLabel}>{patient.therapy.medicationalEfficacy[0].effectiveness}</p>
                </div>
                <div className={style.MedicationRowContainer}>
                  <p className={TextLabel}>Andere medikamentöse Therapien</p>
                  <p className={TextLabel}>
                    {patient.therapy.triedOtherDrugTherapies ? `${patient.therapy.otherDrugTherapies}` : 'nein'}
                  </p>
                </div>
                <div className={style.MedicationRowContainer}>
                  <p className={TextLabel}>THC-haltiges Cannabis ausprobiert</p>
                  <p className={TextLabel}>{patient.cannabisTherapy.triedThcCannabis ? 'ja' : 'nein'}</p>
                </div>
                <div className={style.MedicationRowContainer}>
                  <p className={TextLabel}>Bereits mit Cannabis behandelt</p>
                  <p className={TextLabel}>
                    {patient.cannabisTherapy.hasMedicalCannabisTreatment
                      ? `ja: ${patient.cannabisTherapy.preparation}`
                      : 'nein'}
                  </p>
                </div>
                <div className={style.MedicationRowContainer}>
                  <p className={TextLabel}>Zustand durch Cannabis-Therapie verbessert</p>
                  <p className={TextLabel}>{patient.cannabisTherapy.hasImprovedCondition ? 'ja' : 'nein'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h1 className={GreenHeading}>Nebendiagnosen</h1>
          <div className={style.SecondaryDiagnosisContainer}>
            <div className={style.SecondaryDiagnosisRowContainer}>
              <p className={style.SecondaryDiagnosisTextLabel}>Weitere Krankheiten</p>
              <div className={style.ColumnContainer}>
                <ul className={style.ListItems}>
                  {otherDiseasesLabels()
                    .filter((element) => {
                      if (element === ' other') {
                        return false;
                      }

                      return true;
                    })
                    .map((element, index) => {
                      return (
                        <li className={style.SecondaryDiagnosisBoldText} key={index}>
                          {element}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div className={style.SecondaryDiagnosisRowContainer}>
              <p className={style.SecondaryDiagnosisTextLabel}>Tumor- / Krebserkrankung</p>
              <p className={style.SecondaryDiagnosisBoldText}>
                {patient.secondaryDiagnosis.cancerousDisease ? `ja: ${patient.secondaryDiagnosis.cancerous}` : 'nein'}
              </p>
            </div>
            <div className={style.SecondaryDiagnosisRowContainer}>
              <p className={style.SecondaryDiagnosisTextLabel}>Gynäkologische Anamnese</p>
              <div className={style.ColumnContainer}>
                <ul className={style.ListItems}>
                  {patient.secondaryDiagnosis.gynecologicalAnamnesis &&
                    gynecologicalAnamnesisLabels(patient.secondaryDiagnosis.gynecologicalAnamnesis).map(
                      (element, index) => {
                        return (
                          <li className={style.SecondaryDiagnosisBoldText} key={index}>
                            {element}
                          </li>
                        );
                      }
                    )}
                  {patient.secondaryDiagnosis.gynecologicalAnamnesisOther && (
                    <li className={style.SecondaryDiagnosisBoldText}>
                      {patient.secondaryDiagnosis.gynecologicalAnamnesisOther}
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={style.SicknessMedicationContainer}>
            <div>
              <h1 className={GreenHeading}>Beschwerden & Schmerzen</h1>
              <div className={style.ComplaintsContainer}>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Beschwerden durchschnittlich (letzte 4 Wochen)</p>
                  <p className={TextLabel}>{patient.complaints[0].average}</p>
                </div>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Beschwerden maximal (letzte 4 Wochen)</p>
                  <p className={TextLabel}>{patient.complaints[0].maximum}</p>
                </div>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Beschwerden mindestens (letzte 4 Wochen)</p>
                  <p className={TextLabel}>{patient.complaints[0].minimal}</p>
                </div>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>So stark dürfen Beschwerden sein</p>
                  <p className={TextLabel}>{patient.complaints[0].maximumStrength}</p>
                </div>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Schmerzintensität</p>
                  <p className={TextLabel}>{patient.additionalQuestions.pains}</p>
                </div>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Schlafstörungen durch Schmerzen</p>
                  <p className={TextLabel}>{patient.additionalQuestions.hasSleepDisorder ? 'ja' : 'nein'}</p>
                </div>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Schmerzen behandelt durch</p>

                  {patient.additionalQuestions.previousTreatments && (
                    <div className={style.ColumnContainer}>
                      <ul className={style.ListItems}>
                        {TreatmentMethodsLabels(patient.additionalQuestions.previousTreatments)
                          .filter((element) => {
                            if (element === 'further') {
                              return false;
                            }

                            return true;
                          })
                          .map((element, index) => {
                            return (
                              <li className={TextLabel} key={index}>
                                {element}
                              </li>
                            );
                          })}
                        {patient.additionalQuestions.previousTreatmentsFurther && (
                          <li className={TextLabel}>{patient.additionalQuestions.previousTreatmentsFurther}</li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <h1 className={GreenHeading}>Beeinträchtigung Lebensqualität</h1>
              <div className={style.LifeQualityContainer}>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Stimmung</p>
                  <p className={TextLabel}>{patient.lifeQuality[0].mood}</p>
                </div>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Lebensfreude</p>
                  <p className={TextLabel}>{patient.lifeQuality[0].joyOfLife}</p>
                </div>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Allgemeine Aktivität / körperliche Belastbarkeit</p>
                  <p className={TextLabel}>{patient.lifeQuality[0].generalActivity}</p>
                </div>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Appetit</p>
                  <p className={TextLabel}>{patient.lifeQuality[0].appetite}</p>
                </div>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Sexualität</p>
                  <p className={TextLabel}>{patient.lifeQuality[0].sexuality}</p>
                </div>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Beruf / Alltägliche Arbeit</p>
                  <p className={TextLabel}>{patient.lifeQuality[0].everydayWork}</p>
                </div>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Geistige Tätigkeiten / Konzentration</p>
                  <p className={TextLabel}>{patient.lifeQuality[0].concentration}</p>
                </div>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Schlaf</p>
                  <p className={TextLabel}>{patient.lifeQuality[0].sleep}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h1 className={GreenHeading}>Dokumente</h1>
          <div className={style.DocumentsContainer}>
            <div className={style.DocumentsRowContainer}>
              <div>
                <p className={style.DocumentsTextLabel}>Erstellt</p>
              </div>
              <div>
                <p className={style.DocumentsTextLabel}>Dateiname</p>
              </div>
              <div>
                <p className={style.DocumentsTextLabel}>Vorschau</p>
              </div>
              <div></div>
            </div>
            {documents &&
              documents.map((element: DocumentTypes, index: number) => {
                return (
                  <div className={style.DocumentsRowContainer} key={index}>
                    <div>
                      <p className={style.DocumentsTextLabel}>
                        {new Date(element.created)
                          .toLocaleString('de-DE', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          })
                          .replace('-', '.')}
                      </p>
                    </div>
                    <div>
                      <p className={style.DocumentsTextBold}>{element.filename}</p>
                    </div>
                    <div>{createImg(element.file, element.filename, element.thumbnail)}</div>
                    <div>{createLink(element.file, element.filename)}</div>
                  </div>
                );
              })}
          </div>
        </div>
        <div>
          <div className={style.SicknessMedicationContainer}>
            <div>
              <h1 className={GreenHeading}>Weitere Informationen</h1>
              <div className={style.FurtherInfoContainer}>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Allergien</p>
                  <p className={TextLabel}>{patient.hasAllergies ? `ja: ${patient.allergies}` : 'nein'}</p>
                </div>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Raucht nikotinhaltige Zigaretten</p>
                  <p className={TextLabel}>{patient.isSmoking ? `ja: ${patient.howOftenSmoking}` : 'nein'}</p>
                </div>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>E-Zigarette oder Shisha</p>
                  <p className={TextLabel}>
                    {patient.smokingShisha
                      .replace('permanent', 'Dauernd')
                      .replace('daily', 'Täglich')
                      .replace('weekly', 'Wöchentlich')
                      .replace('monthly', 'Monatlich')
                      .replace('lessFrequently', 'Seltener')}
                  </p>
                </div>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Trinkt Alkohol</p>
                  <p className={TextLabel}>
                    {patient.drinkingAlcohol
                      .replace('permanent', 'Dauernd')
                      .replace('daily', 'Täglich')
                      .replace('weekly', 'Wöchentlich')
                      .replace('monthly', 'Monatlich')
                      .replace('lessFrequently', 'Seltener')}
                  </p>
                </div>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>llegale Substanzen in den letzten 10 Jahren</p>
                  <p className={TextLabel}>{patient.hasTakenNarcotics ? `ja: ${patient.narcotics || ''}` : 'nein'}</p>
                </div>
              </div>
            </div>
            <div>
              <h1 className={GreenHeading}>Formales</h1>
              <div className={style.FormalContainer}>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Minderung der Erwerbsfähigkeit</p>
                  <p className={TextLabel}>{patient.cannabisTherapy.hasReductionGainfulEmployment ? 'ja' : 'nein'}</p>
                </div>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Schwerbehindertengrad festgestellt</p>
                  <p className={TextLabel}>{patient.cannabisTherapy.hasServereDisability ? 'ja' : 'nein'}</p>
                </div>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Rentenantrag gestellt</p>
                  <p className={TextLabel}>{patient.cannabisTherapy.hasPensionApplication ? 'ja' : 'nein'}</p>
                </div>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Arbeitsunfähigkeit festgestellt oder langfristig krankgeschrieben</p>
                  <p className={TextLabel}>{patient.cannabisTherapy.isLongTermSickness ? 'ja' : 'nein'}</p>
                </div>
                <div className={style.ComplaintsRowContainer}>
                  <p className={TextLabel}>Gesetzlicher Betreuer oder Betreuung angedacht</p>
                  <p className={TextLabel}>{patient.cannabisTherapy.hasSupport ? 'ja' : 'nein'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {patient.comment && (
          <div>
            <h1 className={GreenHeading}>Freitext des patienten</h1>
            <div className={style.FreeTextContainer}>
              <p className={style.FreeText}>{patient?.comment}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
