// import React from 'react';
// import { useLocation } from 'react-router-dom';
// import classNames from 'classnames/bind';
// import type { Patient as PatientType } from '../../../../types';
// import style from './Tracking.module.scss';

// const cx = classNames.bind(style);

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
export function Tracking({ props }: any): JSX.Element {
  return (
    <div>
      <h1>Tracking</h1>
    </div>
  );
}
