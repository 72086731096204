import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import { UserService } from '../../../services';
import type {
  Appointment as AppointmentTypes,
  DoctorsComments as DoctorsCommentsTypes,
  FollowRecipe as FollowRecipeTypes,
  Medication as MedicationType,
  Patient as PatientType,
  PatientDocuments as DocumentTypes,
} from '../../../types';
import { Loader } from '../../atoms';
import { NavigationDrawer } from '../../organisms';
import { LineChart } from '../../organisms/LineChart/LineChart';
import { PatientNavigation } from '../../organisms/PatientNavigation/PatientNavigation';
import { Appointments } from './Appointments/Appointments';
import { FollowRecipe } from './FollowRecipe/FollowRecipe';
import { Medication } from './Medication/Medication';
import { Record } from './Record/Record';
import { Tracking } from './Tracking/Tracking';
import style from './Patient.module.scss';

const cx = classNames.bind(style);
const GreenHeading = cx('GreenHeading');
const pageClasses = cx('container', { Patient: true });
const TextLabel = cx('TextLabel');
const TextBold = cx('TextBold');
const GreenText = cx('GreenText');

// Example to Save routes for only authenticated users
// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx

export function Patient(): JSX.Element {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState('Overview');
  const [patient, setPatient] = useState<PatientType>();
  const [loading, setLoading] = useState(true);
  const [mainDiseaseLabels, setMainDiseaseLabels] = useState('');
  const [nextAppointment, setNextAppointment] = useState<Date | string>('');
  const [lastVSS, setLastVSS] = useState(0);
  const [currentMedication, setCurrentMedication] = useState<MedicationType[]>([]);
  const [prevMedication, setPrevMedication] = useState<MedicationType[]>([]);
  const [preparation, setPreparation] = useState<MedicationType[]>([]);
  const [availableFollowUpPrescription, setAvailableFollowUpPrescription] = useState<number>(0);
  const [documents, setDocuments] = useState<DocumentTypes[]>([]);
  const [doctorsComments, setDoctorsComments] = useState<DoctorsCommentsTypes[]>([]);

  const getLastVSS = (d: AppointmentTypes) => {
    const date = d;

    const now = new Date();
    const lastAppointmentDate = date.filter((dates) => new Date(dates?.startDate) < now && dates.type === 1);

    const date1 = new Date(lastAppointmentDate[lastAppointmentDate.length - 1]?.startDate);

    const oneDay = 1000 * 60 * 60 * 24;
    const diffInTime = now.getTime() - date1.getTime();

    const diffInDays: number = Math.round(diffInTime / oneDay);
    setLastVSS(diffInDays);
  };

  const getNextAppointment = (d: AppointmentTypes) => {
    const date = d;
    const lastAppointmentDate = new Date(date[date.length - 1]?.startDate);
    const now = new Date();

    if (lastAppointmentDate > now) {
      setNextAppointment(lastAppointmentDate);
    }
  };

  const convertLabels = (d: string) => {
    const newString = d
      .replace('chronicPains', 'Chronische Schmerzen')
      .replace('sleepDisorders', 'Schlafstörungen')
      .replace('migraine', 'Migräne')
      .replace('adhs', 'AD(H)S')
      .replace('depression', 'Depression')
      .replace('otherComplaints', 'Andere Beschwerden');
    setMainDiseaseLabels(newString);
  };

  const getAvailableFollowUpPrescription = (patientsResponse: FollowRecipeTypes) => {
    if (patientsResponse.availableFollowUpPrescription !== undefined) {
      sessionStorage.setItem('AvailableFollowUpPrescription', patientsResponse.availableFollowUpPrescription);
      setAvailableFollowUpPrescription(patientsResponse.availableFollowUpPrescription);
    }
  };

  const getCurrentMedication = (medication: MedicationType[]) => {
    setCurrentMedication(() => {
      const foundMedication = medication.filter(
        (med: MedicationType) => med.start !== undefined && med.end === undefined
      );

      return foundMedication[0]?.preparationListItem;
    });
  };

  const getPreviousMedication = (medication: MedicationType[]) => {
    setPrevMedication(() => {
      const foundMedication = medication.filter(
        (med: MedicationType) => med.start !== undefined && med.end !== undefined
      );

      return foundMedication.reverse().slice(0, 2);
    });
  };

  const getPreparation = async () => {
    const preparationResponse = await UserService.getPreparation();

    setPreparation(preparationResponse.data);
  };

  const getDoctorsComment = async () => {
    const DoctorsCommentResponse = await UserService.getDoctorsComments(Number(params.patientId));
    if (DoctorsCommentResponse.error) {
      console.log(DoctorsCommentResponse.error);
    }
    if (DoctorsCommentResponse.data) {
      setDoctorsComments(DoctorsCommentResponse.data);
    }
  };

  const getDocuments = async () => {
    const documentsResponse = await UserService.getDocuments(Number(params.patientId));
    if (documentsResponse.error) {
      console.log(documentsResponse);
    }

    if (documentsResponse.data) {
      setDocuments(documentsResponse.data);
    }
  };

  const getPatient = async () => {
    const patientsResponse = await UserService.getPatient(Number(params.patientId));
    sessionStorage.setItem('savedMedication', '[]');
    sessionStorage.setItem('AvailableFollowUpPrescription', '0');

    if (patientsResponse.error) {
      console.log(patientsResponse);
    }

    if (patientsResponse.data) {
      setPatient(patientsResponse.data);
      convertLabels(patientsResponse.data.firstPresentation.mainDisease);
      getLastVSS(patientsResponse.data.appointment);
      getNextAppointment(patientsResponse.data.appointment);
      getAvailableFollowUpPrescription(patientsResponse.data);

      if (patientsResponse.data.medication && patientsResponse.data.medication.length !== 0) {
        getPreviousMedication(patientsResponse.data.medication);
        getCurrentMedication(patientsResponse.data.medication);

        const lastMedication = await patientsResponse.data.medication.filter(
          (medication: MedicationType) => medication.start !== undefined && medication.end === undefined
        );
        if (lastMedication.length !== 0 && lastMedication.length !== undefined) {
          sessionStorage.setItem('savedMedication', JSON.stringify(lastMedication[0]?.preparationListItem));
        }
      }
    }
  };

  useEffect(() => {
    if (location.pathname.endsWith('details')) {
      navigate(`/patients/${params.patientId}`);
      setCurrentPage('FollowRecipe');
    }

    getPatient().then(() => {
      setLoading(false);
      getDoctorsComment();
      getDocuments();
    });
    getPreparation();
  }, []);

  return (
    <div className={pageClasses}>
      <NavigationDrawer />
      <div>
        <PatientNavigation
          firstname={patient?.firstname}
          lastname={patient?.lastname}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          currentMedication={currentMedication}
          setCurrentMedication={setCurrentMedication}
          availableFollowUpPrescription={availableFollowUpPrescription}
          setAvailableFollowUpPrescription={setAvailableFollowUpPrescription}
        />
        {loading && <Loader />}
        {!loading && currentPage === 'Overview' && (
          <>
            <div className={style.MainContainer}>
              <div className={style.FirstContainer}>
                <div className={style.NameAppointmentContainer}>
                  <div className={style.Flex5}>
                    <h1 className={GreenHeading}>ALLGEMEIN</h1>
                    <div className={style.GeneralInfoContainer}>
                      <div className={style.NameDiseaseContainer}>
                        <div className={style.PatientName}>
                          <p className={TextLabel}>Patient</p>
                          <p className={TextBold}>
                            {patient?.firstname} {patient?.lastname},{' '}
                            {patient?.birthday &&
                              new Date(patient?.birthday)
                                .toLocaleString('de-DE', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                })
                                .replace('-', '.')}
                          </p>
                        </div>
                        <div>
                          <p className={TextLabel}>Hauptdiagnose</p>
                          <p className={TextBold}>{mainDiseaseLabels}</p>
                        </div>
                      </div>
                      <div className={style.RowContainer}>
                        <div className={style.MarginRight70}>
                          <p className={TextLabel}>Status</p>
                          {patient?.active ? <p className={GreenText}>aktiv</p> : <p className={TextBold}>inaktiv</p>}
                        </div>
                        <div className={style.MarginRight50}>
                          <p className={TextLabel}>Tracking</p>
                          {patient?.isPatientTracking ? (
                            <p className={GreenText}>aktiv</p>
                          ) : (
                            <p className={TextBold}>inaktiv</p>
                          )}
                        </div>
                        <div>
                          <p className={TextLabel}>Folgerezept</p>
                          {
                            // eslint-disable-next-line no-constant-condition
                            false ? <p className={GreenText}>aktiv</p> : <p className={TextBold}>inaktiv</p>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={style.Flex2}>
                    <h1 className={GreenHeading}>TERMINE</h1>
                    <div className={style.AppointmentContainer}>
                      <div className={style.RowContainer}>
                        <div className={style.ColumnContainer}>
                          <p className={TextLabel}>Erstgespräch</p>
                          <p className={TextLabel}>Letzte VSS</p>
                          <p className={TextLabel}>nächster Termin</p>
                        </div>
                        <div className={style.ColumnContainer}>
                          <p className={style.AppointmentText}>
                            {patient?.appointment[0]?.startDate &&
                              new Date(patient?.appointment[0]?.startDate)
                                .toLocaleString('de-DE', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                })
                                .replace('-', '.')}
                          </p>
                          {lastVSS > 20 && <p className={style.RedAppointmentText}>{`vor ${lastVSS}d`}</p>}
                          {lastVSS > 0 && lastVSS <= 20 && <p className={style.AppointmentText}>{`vor ${lastVSS}d`}</p>}
                          {!lastVSS && <p className={style.AppointmentText}> </p>}
                          {nextAppointment ? (
                            <p className={style.NextAppointmentText}>
                              {new Date(nextAppointment)
                                .toLocaleString('de-DE', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: '2-digit',
                                })
                                .replace('-', '.')}
                              <br />
                              {new Date(nextAppointment)
                                .toLocaleString('de-DE', {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })
                                .replace('-', '.')}{' '}
                              {' Uhr'}
                              <br />
                              Online
                            </p>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h1 className={GreenHeading}>TRACKING LEBENSQUALITÄT</h1>
                  <div className={style.ChartContainer}>
                    <div>
                      <LineChart props={patient?.lifeQuality} />
                    </div>
                  </div>
                </div>
              </div>
              {currentMedication.length !== 0 && prevMedication.length !== 0 && (
                <div className={style.SecondContainer}>
                  {currentMedication.length !== 0 && (
                    <div>
                      <h1 className={style.Heading}>AKTUELLE MEDIKATION</h1>
                      <div className={style.MedicationContainer}>
                        {currentMedication &&
                          currentMedication.map((element: MedicationType) => {
                            return (
                              <div className={style.MedicineContainer} key={element.id}>
                                <p className={style.MedicineText}>{element.name}</p>
                                <p className={TextLabel}>{`${element.quantity} ${element.dosageUnit}`}</p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}

                  {prevMedication.length !== 0 && (
                    <div>
                      <h1 className={style.Heading}>VORHERIGE MEDIKATIONEN</h1>
                      {prevMedication &&
                        prevMedication.map((d: MedicationType) => {
                          return (
                            <div className={style.MedicationContainer} key={d.id}>
                              {d.preparationListItem.map((e: MedicationType) => {
                                return (
                                  <div className={style.MedicineContainer} key={e.preparationId}>
                                    <p className={style.MedicineText}>{e.name}</p>
                                    <p className={style.DosageText}>{`${e.quantity} ${e.dosageUnit}`}</p>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                    </div>
                  )}

                  {prevMedication && prevMedication.length !== 0 && (
                    <div>
                      <h1 className={GreenHeading}>VORHERIGE MEDIKATIONEN</h1>
                      {prevMedication.map((d: MedicationType) => {
                        return (
                          <div className={style.MedicationContainer} key={d.id}>
                            {d.preparationListItem.map((e: MedicationType) => {
                              return (
                                <div className={style.MedicineContainer} key={e.preparationId}>
                                  <p className={style.MedicineText}>{e.name}</p>
                                  <p className={TextLabel}>
                                    {e.dosageMax}
                                    {e.dosageUnit}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
        {!loading && currentPage === 'Record' && (
          <Record
            patient={patient}
            mainDiseaseLabels={mainDiseaseLabels}
            documents={documents}
            doctorsComments={doctorsComments}
          />
        )}
        {!loading && currentPage === 'Medication' && (
          <Medication
            currentMedication={currentMedication}
            setCurrentMedication={setCurrentMedication}
            preparation={preparation}
          />
        )}
        {!loading && currentPage === 'Appointments' && <Appointments />}
        {!loading && currentPage === 'Tracking' && <Tracking />}
        {!loading && currentPage === 'FollowRecipe' && (
          <FollowRecipe
            currentMedication={currentMedication}
            followUpPrescriptionQuestion={patient?.followUpPrescriptionQuestion}
            followUpPrescriptionRequest={patient?.followUpPrescriptionRequest}
            availableFollowUpPrescription={availableFollowUpPrescription}
            setAvailableFollowUpPrescription={setAvailableFollowUpPrescription}
            getPatient={getPatient}
          />
        )}
      </div>
    </div>
  );
}
