import React from 'react';
import classNames from 'classnames/bind';
import style from './Button.module.scss';

const cx = classNames.bind(style);

type ButtonProps = {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  type: 'primary' | 'secondary';
};

export function Button({ children, type, ...props }: ButtonProps): JSX.Element {
  const ButtonClasses = cx(
    {
      Button: true,
      [`Button-${type}`]: true,
    },
    props.className
  );

  return (
    <button className={ButtonClasses} onClick={props.onClick}>
      {children}
    </button>
  );
}
