import React from 'react';
import classNames from 'classnames/bind';
import style from './Loader.module.scss';

const cx = classNames.bind(style);

export function Loader(): JSX.Element {
  const loaderContainerClasses = cx({ LoaderContainer: true });
  const loaderClasses = cx({ Loader: true });
  const loaderShapClasses = cx({ LoaderShap: true });

  return (
    <div className={loaderContainerClasses}>
      <div className={loaderClasses}>
        <div className={loaderShapClasses} />
        <div className={loaderShapClasses} />
        <div className={loaderShapClasses} />
      </div>
    </div>
  );
}
