import React, { useEffect, useState } from 'react';
import { decodeToken, isExpired } from 'react-jwt';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { UserService } from '../../../services';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { login, selectIsAuthenticated } from '../../../store/reducers';
import type { Token } from '../../../types';
import { Button, Icon } from '../../atoms';
import { Input } from '../../molecules';
import style from './Login.module.scss';

const cx = classNames.bind(style);

// Example to Save routes for only authenticated users
// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx

export function Login(): JSX.Element {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const token = sessionStorage.getItem('jwt');
    const name = sessionStorage.getItem('name');
    const mail = sessionStorage.getItem('email');

    const isTokenExpired = token ? isExpired(token) : true;

    if (token && name && mail && !isTokenExpired) {
      dispatch(login({ user: { name, email: mail }, token, isAuthenticated: true }));
    }

    if (isAuthenticated || (token && name && mail)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const from = location.state?.from?.pathname || '/';
      navigate(from, { replace: true });
    }

    if (token && isTokenExpired) {
      sessionStorage.removeItem('jwt');
      sessionStorage.removeItem('name');
      sessionStorage.removeItem('email');
    }
  }, []);

  const loginButton = async (): Promise<void> => {
    const authData = {
      email,
      password,
    };
    const loginResponse = await UserService.login(authData);
    if (loginResponse.error) {
      console.log(loginResponse);
      if (
        loginResponse.message.response.data.error === 'email' ||
        loginResponse.message.response.data.error === 'password'
      ) {
        toast.error('Logindaten sind fehlerhaft.');
      } else {
        toast.error('Etwas ist schief gelaufen.');
      }
    }

    if (!loginResponse.error) {
      const decodedToken = decodeToken(loginResponse.data.token) as Token;
      const isDoctor = decodedToken.role === 2;

      if (!isDoctor) {
        toast.error('Du hast keine Berechtigung für diesen Dienst!');

        return;
      }

      const userData = {
        name: loginResponse.data.name,
        email: loginResponse.data.email,
      };
      sessionStorage.setItem('name', loginResponse.data.name);
      sessionStorage.setItem('email', loginResponse.data.email);
      sessionStorage.setItem('jwt', loginResponse.data.token);
      dispatch(login({ user: userData, token: loginResponse.data.token, isAuthenticated: true }));
      setEmail('');
      setPassword('');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const from = location.state?.from?.pathname || '/';
      navigate(from, { replace: true });
    }
  };

  const LoginPageClasses = cx({ Login: true });
  const LoginBoxClasses = cx({ LoginBox: true });
  const LogoContainerClasses = cx('margin-10');
  const InputClasses = cx('margin-10');
  const ButtonClasses = cx('margin-10');

  return (
    <div className={LoginPageClasses}>
      <div className={LoginBoxClasses}>
        <div className={LogoContainerClasses}>
          <Icon icon='logo' size={160} />
        </div>
        <Input
          onChange={(event) => setEmail(event.target.value)}
          className={InputClasses}
          type='text'
          icon='magnify'
          placeholder='Username...'
          size={15}
        />
        <Input
          onChange={(event) => setPassword(event.target.value)}
          className={InputClasses}
          type='password'
          icon='magnify'
          placeholder='Passwort...'
          size={15}
        />
        <Button className={ButtonClasses} type='primary' onClick={loginButton}>
          Login
        </Button>
        <Button type='secondary' onClick={() => navigate('/password-forgot')}>
          Passwort vergessen?
        </Button>
      </div>
    </div>
  );
}
