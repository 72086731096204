import { Typography } from '@mui/material';
import classNames from 'classnames/bind';
import { Icon, Text } from '../../atoms';
import style from './PatientsHeader.module.scss';

const cx = classNames.bind(style);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function PatientsHeader({ activePatientsNumber, patientsNumber }: any) {
  const DivClasses = cx({ PatientsHeaderDiv: true });
  const TitleDivClasses = cx({ PatientsHeaderTitleDiv: true });
  const TitleTextClasses = cx({ PatientsHeaderTitleText: true });
  const TextDivClasses = cx({ PatientsHeaderTextDiv: true });
  const TextClasses = cx({ PatientsHeaderText: true });

  return (
    <div className={DivClasses}>
      <Icon icon='account-plus' size={70} />
      <div className={TitleDivClasses}>
        <Typography variant='h4' className={TitleTextClasses}>
          {' '}
          MEINE PATIENTEN{' '}
        </Typography>
        <div className={TextDivClasses}>
          <div className={TextClasses}>
            <Text>Aktive Patienten: {activePatientsNumber}</Text>
          </div>
          <div className={TextClasses}>
            <Text>Inaktive Patienten: {patientsNumber - activePatientsNumber}</Text>
          </div>
          <div className={TextClasses}>
            <Text>Patienten gesamt: {patientsNumber}</Text>
          </div>
        </div>
      </div>
    </div>
  );
}
